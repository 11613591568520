import React, { useState } from 'react'
import slideShow from './slideshow.module.scss'
import Image from '../image/image.component'
// slideshow

export default function SlideShow({ className, images = [] }) {
  const [currentImage, setCurrentImage] = useState(0)
  const handleClick = key => setCurrentImage(key)
  const isSlider = images.length > 1 ? true : false

  return (
    <div
      className={`${slideShow.container} ${isSlider ? slideShow.slide : ''}`}
    >
      <div className={slideShow.slideContainer}>
        {images.map((image, key) => (
          <div
            key={key}
            className={`${className} ${slideShow.image} ${
              currentImage === key ? slideShow.active : ''
            }`}
          >
            {currentImage === key && (
              <Image src={image.localFile.childImageSharp.fluid} />
            )}
          </div>
        ))}
      </div>
      <div className={slideShow.bullets}>
        {isSlider && (
          <ul>
            {images.map((_, key) => (
              <li key={key} className={`${slideShow.button}`}>
                <a
                  className={currentImage === key ? slideShow.active : ''}
                  onClick={() => handleClick(key)}
                  //onMouseOver={() => handleClick(key)}
                >
                  {key}
                </a>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}
