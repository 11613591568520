import React, { useContext, useEffect, useState } from 'react'
import { graphql, navigate } from 'gatsby'
import artworkStyles from './artwork.module.scss'
import Layout from '../../components/layout/layout.component'
import Search from '../../components/search/search.component'
import ArrowRoom from '../../components/arrowRoom/arrowRoom.comonent'
import ArrowsLeftRight from '../../components/arrowsLR/arrows.left.right.component'
import Img from 'gatsby-image'
import ThemeContext from '../../context/ThemeContext'
import { useSize } from '../../hooks/size.hook'
import SlideShow from '../../components/slideshow/slideshow.component'
import Button from '../../components/button/button.component'

export const query = graphql`
   query($limit: Int!, $skip: Int!, $code: String) {
      current: nodeArtwork(field_object_number: { eq: $code }) {
         id
         title
         field_medium
         field_media_quantity
         field_has_background
         field_primary_dimensions
         field_object_number
         field_show_shop_link
         field_shop_link {
            uri
            title
         }

         field_image {
            alt
            title
            height
         }

         field_date
         status

         relationships {
            imgDescktop: field_image {
               localFile {
                  childImageSharp {
                     fluid(maxHeight: 650, quality: 60) {
                        src
                        base64
                     }
                  }
               }
            }
            imgMobile: field_image {
               localFile {
                  childImageSharp {
                     fluid(maxHeight: 300, quality: 50) {
                        src
                        base64
                     }
                  }
               }
            }
            field_category {
               name
            }
         }
      }
      pagination: allNodeArtwork(skip: $skip, limit: $limit) {
         nodes {
            field_object_number
            title
         }
      }
      file(relativeDirectory: { eq: "backgrounds" }, base: { eq: "f1.jpg" }) {
         childImageSharp {
            fixed(width: 1600, quality: 68) {
               ...GatsbyImageSharpFixed
            }
         }
      }
   }
`

export default function Artwork({ data, pageContext }) {
   //console.log(pageContext)
   const {
      current: {
         field_has_background,
         field_object_number,
         field_medium,
         field_date,
         field_show_shop_link,
         field_shop_link,
         field_primary_dimensions,
         relationships: { field_category, imgMobile, imgDescktop },
         title,
      },
      pagination: { nodes },
   } = data

   console.log(data)

   const context = useContext(ThemeContext)
   const { isMobile, isMobLandscape } = useSize()
   const [isSearchOpen, setIsSearchOpen] = useState(false)
   const [isLoaded, setIsLoaded] = useState(false)

   useEffect(() => {
      /*
       *Key down event right and left buttons
       */
      context.open && context.toggleOpen()
      /*
       * Set 100vh by calc
       * */
      setTimeout(() => {
         setIsLoaded(true)
      }, 200)
      document.addEventListener('keyup', onKeyDown)
      return () => {
         document.removeEventListener('keyup', onKeyDown)
      }
   }, [isMobile, isMobLandscape])

   const handleClick = e => {
      let numNode = null
      switch (e) {
         case 'next':
            numNode = 2
            break
         case 'prev':
            numNode = 0
            break
         default:
            numNode = 2
            break
      }
      navigate(`/artwork/${nodes[numNode].field_object_number}`)
   }

   const onToggle = e => {
      !e && document.removeEventListener('keyup', onKeyDown)
      setIsSearchOpen(e)
   }

   /*
    * Key down event
    */
   const onKeyDown = e => {
      e.preventDefault()
      if (e.which === 37 && pageContext.prevPage !== -1) handleClick('prev')
      if (e.which === 39 && pageContext.nextPage !== -1) handleClick('next')
   }

   const shopButton =
      field_show_shop_link || field_shop_link
         ? field_shop_link?.uri !== 'http://default.pe/field_object_number'
            ? field_shop_link?.uri
               ? field_shop_link.uri
               : `${process.env.SHOP_URL}${field_object_number}`
            : field_show_shop_link &&
              `${process.env.SHOP_URL}${field_object_number}`
         : false

   return (
      <>
         <Layout
            goBack={true}
            hasBackground={field_has_background}
            isMenuWhite={field_has_background}
            showFooter
            className={`${field_has_background ? '' : artworkStyles.light}`}
            title={`${title} - ${field_category.name}`}
            description={`${field_medium} ${field_object_number}`}
            to='/studio/workshop'
         >
            <div
               className={`${artworkStyles.container} ${
                  isLoaded ? artworkStyles.height : ''
               }`}
            >
               <div className={artworkStyles.description}>
                  <h1 className={artworkStyles.title}>
                     {title}, {field_date}
                     <span>{field_category.name}</span>
                  </h1>
                  {!isMobLandscape && (
                     <div className={artworkStyles.paragraph}>
                        <p>{field_medium}</p>
                        <p>{field_primary_dimensions}</p>
                     </div>
                  )}
                  {shopButton && (
                     <Button
                        onClick={() => navigate(shopButton)}
                        customClass={artworkStyles.button}
                     >
                        Buy Artwork
                     </Button>
                  )}
               </div>
               {imgMobile && imgDescktop && imgMobile[0].localFile && (
                  <div className={artworkStyles.slideContainer}>
                     <SlideShow
                        className={artworkStyles.square}
                        images={isMobile ? imgMobile : imgDescktop}
                     />
                  </div>
               )}
               {field_has_background && (
                  <Img
                     fixed={data.file.childImageSharp.fixed}
                     objectFit='cover'
                     objectPosition='top center'
                     alt=''
                     style={{
                        width: '100%',
                        height: isMobile ? '125vh' : '100vh',
                        position: 'absolute',
                        zIndex: 0,
                     }}
                  />
               )}
            </div>
         </Layout>

         <div className={artworkStyles.bottomSection}>
            {!context.open && (
               <Search
                  className={artworkStyles.search}
                  hasBackground={!context.open ? field_has_background : true}
                  onToggle={onToggle}
                  currentArtwork={field_object_number}
                  pageContext={pageContext}
               />
            )}

            {context.isStudio && (
               <ArrowRoom
                  hasBackground={field_has_background}
                  to={`/virtual-exhibitions?category=${field_category.name.toLowerCase()}`}
                  category={
                     field_category.name[0].toUpperCase() +
                     field_category.name.substring(1)
                  }
               />
            )}

            {!context.open && !isSearchOpen && !context.isStudio && (
               <ArrowsLeftRight
                  hasBackground={field_has_background}
                  onClick={handleClick}
                  prev={pageContext.prevPage === -1 ? false : true}
                  next={pageContext.nextPage === -1 ? false : true}
               />
            )}
         </div>
      </>
   )
}
