import React from 'react'
import Next from '../next/next.component'
import arrowsLeftRightStyles from './arrows.left.right.module.scss'

export default function ArrowsLeftRight({
  className,
  hasBackground,
  onClick,
  prev = true,
  next = true,
  ...otherProps
}) {
  const action = e => {
    //console.log(e.currentTarget.dataset.direction)
    onClick && onClick(e.currentTarget.dataset.direction)
  }
  return (
    <nav
      className={`
              ${arrowsLeftRightStyles.nav}  
              ${className}
            `}
      {...otherProps}
    >
      <button
        className={`${arrowsLeftRightStyles.btn} ${
          arrowsLeftRightStyles.btnNav
        } ${prev ? '' : arrowsLeftRightStyles.hidden}`}
        onClick={action}
        data-direction="prev"
      >
        <Next hasBackground={hasBackground} />
      </button>
      <button
        className={`${arrowsLeftRightStyles.btn} ${
          arrowsLeftRightStyles.btnNav
        } ${next ? '' : arrowsLeftRightStyles.hidden}`}
        onClick={action}
        data-direction="next"
      >
        <Next hasBackground={hasBackground} next={true} />
      </button>
    </nav>
  )
}
