import React from 'react'
import { Link } from 'gatsby'
import arrowRoomStyles from './arrowRoom.module.scss'
import Arrow from './arrow.svg'

export default function ArrowRoom({ to, category, hasBackground }) {
  return (
    <Link
      to={to}
      className={`${arrowRoomStyles.arrow} ${
        hasBackground ? '' : arrowRoomStyles.light
      }`}
    >
      <div className={arrowRoomStyles.textLink}>
        Enter {category} Exhibition
      </div>
      <Arrow className={arrowRoomStyles.arrowButton} />
    </Link>
  )
}
