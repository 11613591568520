import React from 'react'
import buttonsStyles from './button.module.scss'

export default function Button({ isSubmit, children, ...otherProps }) {
  return (
    <div
      className={`${buttonsStyles.buttons} ${
        otherProps.customClass ? otherProps.customClass : ''
      }`}
    >
      {isSubmit ? (
        <input
          className={buttonsStyles.button}
          type="submit"
          value={children}
          {...otherProps}
        />
      ) : (
        <button className={buttonsStyles.button} {...otherProps}>
          {children}
        </button>
      )}
    </div>
  )
}
