import React from 'react'
import nextStyles from './next.module.scss'
import IconArrow from './next.svg'

export default function Next({ hasBackground, next, onClick }) {
  const callAction = () => {
    onClick && onClick()
  }
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={callAction}
      className={`${nextStyles.next} 
        ${next ? nextStyles.right : nextStyles.left}
        ${hasBackground ? '' : nextStyles.light}`}
      onKeyDown={callAction}
    >
      <IconArrow className={nextStyles.icon} />
    </div>
  )
}
